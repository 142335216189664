import axios from 'axios';
import config from '../config';

export const getSpecializations = () =>
  axios
    .get(`${config.apiUrl}/specializations`, {
      params: { _sort: 'sortingNumber' },
    })
    .then(({ data }) => data);

export const getSpecializationById = (id: string) =>
  axios.get(`${config.apiUrl}/teammembers/${id}`).then(({ data }) => data);
