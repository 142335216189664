import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Gap from 'components/common/Gap/Gap';
import SectionHeading from 'components/common/SectionHeading/SectionHeading';
import Spinner from 'components/common/Spinner/Spinner';
import { getSpecializations } from 'services/specializations';
import SpecializationsList from './SpecializationsList';
import { ROUTES } from 'types/routes';

const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          start {
            specializations {
              headingLarge
              list {
                title
              }
            }
          }
        }
      }
    }
  }
`;

const JST = 'jst';
const CORPO = 'corpo';

const AllSpecializations = () => {
  const data = useStaticQuery(query);
  const {
    headingLarge,
    list,
  } = data.allDataJson.edges[0].node.start.specializations;
  const [isLoading, setIsLoading] = useState(true);
  const [specializations, setSpecializations] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const corpoExpanded = useMemo(() => urlParams.get('corpo') === 'true', [
    urlParams,
  ]);
  const jstExpanded = useMemo(() => urlParams.get('corpo') === 'false', [
    urlParams,
  ]);
  const [expandedSection, setExpandedSection] = useState('');

  useEffect(() => {
    getSpecializations().then(specializations => {
      setSpecializations(specializations);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    let expandedSection = '';
    if (corpoExpanded) {
      expandedSection = CORPO;
    } else if (jstExpanded) {
      expandedSection = JST;
    }
    setExpandedSection(expandedSection);
  }, []);

  const jstSpecs = useMemo(() => specializations.filter(({ jst }) => jst), [
    specializations,
  ]);
  const corpoSpecs = useMemo(() => specializations.filter(({ jst }) => !jst), [
    specializations,
  ]);

  const onHeaderClick = useCallback(
    toExpand => {
      if (toExpand === expandedSection) {
        setExpandedSection(expandedSection === JST ? CORPO : JST);
      } else {
        setExpandedSection(toExpand);
      }
    },
    [setExpandedSection, expandedSection]
  );

  const navigate = useCallback(id => {
    if (typeof window !== 'undefined') {
      window.location.href = `${ROUTES.SPECIALIZATIONS}?id=${id}`;
    }
  }, []);

  return (
    <>
      <Gap top="md">
        <SectionHeading largeHeading={headingLarge} color="primary" centered />
      </Gap>
      {isLoading ? (
        <Spinner />
      ) : (
        <Gap bottom="md">
          <SpecializationsList
            specializations={jstSpecs}
            heading={list[0].title}
            isExpanded={expandedSection === JST}
            onHeaderClick={() => onHeaderClick(JST)}
            goToDetails={navigate}
          />
          <SpecializationsList
            specializations={corpoSpecs}
            heading={list[1].title}
            isExpanded={expandedSection === CORPO}
            onHeaderClick={() => onHeaderClick(CORPO)}
            goToDetails={navigate}
          />
        </Gap>
      )}
    </>
  );
};

export default AllSpecializations;
