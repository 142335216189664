import React, { useMemo } from 'react';
import AllSpecializations from './AllSpecializations/AllSpecializations';
import Specialization from './Specialization/Specialization';
import Layout from 'components/common/Layout/Layout';
import Header from 'components/common/Header/Header';
import styles from './Specializations.module.scss';

export interface Specialization {
  title: string;
  description: string;
  quote: string;
  id: string;
  fieldsofactivity: { title: string }[];
}

const Specializations = () => {
  if (typeof window === 'undefined') return null;

  const urlParams = new URLSearchParams(window.location.search);
  const newsId = useMemo(() => urlParams.get('id'), [urlParams]);

  return (
    <Layout>
      <Header withBackground block />
      <main className={styles.main}>
        {newsId ? <Specialization id={newsId} /> : <AllSpecializations />}
      </main>
    </Layout>
  );
};

export default Specializations;
