import React, { useEffect, useState, useCallback, FC } from 'react';
import { getSpecializations } from 'services/specializations';
import { Specialization as SpecializationType } from '../Specializations';
import Spinner from 'components/common/Spinner/Spinner';
import Gap from 'components/common/Gap/Gap';
import SectionHeading from 'components/common/SectionHeading/SectionHeading';
import { Row, Col } from 'react-flexbox-grid';
import Text from 'components/common/Text/Text';
import styles from '../Specializations.module.scss';
import { Icon } from 'semantic-ui-react';
import { Link } from 'gatsby';
import { ROUTES } from 'types/routes';

interface SpecializationProps {
  id: string;
}

const navigateToAllSpecializations = () => {
  if (typeof window !== 'undefined') {
    window.location.href = ROUTES.SPECIALIZATIONS;
  }
};

const Specialization: FC<SpecializationProps> = ({ id }) => {
  const [specialization, setSpecialization] = useState<SpecializationType>();
  const [nextSpecializationId, setNextSpecializationId] = useState();

  useEffect(() => {
    getSpecializations().then(specializations => {
      const currentSpecialization = specializations.find(
        (specialization: SpecializationType) => specialization.id === id
      );
      const currentSpecializationIndex = specializations.findIndex(
        (member: SpecializationType) => member.id === id
      );
      const nextSpecializationIndex =
        currentSpecializationIndex === specializations.length - 1
          ? 0
          : currentSpecializationIndex + 1;
      setNextSpecializationId(specializations[nextSpecializationIndex].id);
      setSpecialization(currentSpecialization);
    });
  }, []);

  const navigateToNextSpecialization = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.location.href = `${ROUTES.TEAM}?id=${nextSpecializationId}`;
    }
  }, [nextSpecializationId]);

  if (!specialization) return <Spinner />;

  return (
    <Gap top="lg" isBlock>
      <Gap bottom="lg" isBlock>
        <SectionHeading
          largeHeading={specialization.title}
          color="primary"
          centered
        />
      </Gap>
      <Row between="xs">
        {specialization.quote && (
          <Col xs={12} xl={5} className={styles.quoteCol}>
            <Text
              fontStyle="italic"
              fontSize="1.8rem"
              alternativeFontFamily
              className={styles.quote}
            >
              {specialization.quote}
            </Text>
          </Col>
        )}
        <Col xs={12} xl={specialization.quote ? 6 : 12}>
          <Text fontSize="1.3rem" alternativeFontFamily>
            {specialization.description}
          </Text>
        </Col>
      </Row>
      {specialization.fieldsofactivity &&
        !!specialization.fieldsofactivity.length && (
          <div className={styles.fieldsOfActivitySection}>
            <div className={styles.fieldsOfActivitySectionBack} />
            <Gap bottom="lg" isBlock>
              <Text fontSize="2rem" block color="light">
                W czym możemy Ci pomóc?
              </Text>
            </Gap>
            <Row between="xs">
              {specialization.fieldsofactivity.map(({ title }) => (
                <Text
                  key={title}
                  className={styles.fieldOfActivity}
                  fontSize="1.2rem"
                  color="standard"
                  alternativeFontFamily
                  transform="uppercase"
                >
                  <Icon
                    name="chevron right"
                    className={styles.fieldOfActivityIcon}
                  />
                  {title}
                </Text>
              ))}
            </Row>
            <Gap top="xl">
              <Link to={ROUTES.SPECIALIZATIONS}>
                <Text
                  color="neutral"
                  fontSize="1.3rem"
                  className={styles.goBackLink}
                >
                  <Icon
                    name="chevron left"
                    onClick={navigateToAllSpecializations}
                  />
                  <span>Zobacz wszystkie specjalizacje</span>
                </Text>
              </Link>
            </Gap>
          </div>
        )}
    </Gap>
  );
};

export default Specialization;
