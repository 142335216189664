import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import { Specialization } from '../Specializations';
import Text from 'components/common/Text/Text';
import styles from '../Specializations.module.scss';
import Gap from 'components/common/Gap/Gap';
import { Icon } from 'semantic-ui-react';

interface SpecializationsListProps {
  heading: string;
  specializations: Specialization[];
  isExpanded?: boolean;
  onHeaderClick: () => void;
  goToDetails: (id: string) => void;
}

const SpecializationsList: FC<SpecializationsListProps> = ({
  heading,
  specializations,
  isExpanded,
  onHeaderClick,
  goToDetails,
}) => {
  const calculatedMaxHeight = useMemo(
    () => 70 * Math.ceil(specializations.length / (window.innerWidth > 500 ? 2 : 1)),
    [specializations.length]
  );
  const specializationsStyle = useMemo(
    () => ({ maxHeight: isExpanded ? calculatedMaxHeight : 0 }),
    [calculatedMaxHeight, isExpanded]
  );

  return (
    <Gap top="xl" isBlock className={cn({ [styles.expanded]: isExpanded })}>
      <div onClick={onHeaderClick}>
        <Text
          color="light"
          block
          align="center"
          className={styles.sectionHeading}
          transform="uppercase"
          fontSize="1.5rem"
        >
          {heading}
          <Icon name="chevron down" className={styles.chevronIcon} />
        </Text>
      </div>
      <ul className={styles.specializations} style={specializationsStyle}>
        {specializations.map(({ title, id }) => (
          <li
            key={id}
            className={styles.specialization}
            onClick={() => goToDetails(id)}
          >
            <Icon name="angle right" className={styles.angleIcon} />
            <Text
              color="secondary"
              block
              fontSize="1.2rem"
              className={styles.specializationText}
              weight="bold"
              transform="uppercase"
              alternativeFontFamily
            >
              {title}
            </Text>
          </li>
        ))}
      </ul>
    </Gap>
  );
};

export default SpecializationsList;
